import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntroductionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import AngleRightIcon from '../../../shared/components/Icons/AngleRightIcon';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import {
  getCompanyData,
  getCompanyPagesCustomizations,
  getCurrentStep,
  getJobPostData,
} from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getIntroLoading } from '../../../store/ui/selectors';
import DescriptionText from './DescriptionText';
import VideoPlayer from './VideoPlayer';

interface Props {
  onSubmit: () => void;
}

const IntroBox = ({ onSubmit }: Props) => {
  // Hooks
  const { t } = useTranslation();

  // Selectors
  const loading = useAppSelector(getIntroLoading);
  const currentStep = useAppSelector(getCurrentStep) as IntroductionStep | null;
  const companyData = useAppSelector(getCompanyData);
  const jobPostData = useAppSelector(getJobPostData);
  const pagesCustomization = useAppSelector(getCompanyPagesCustomizations);

  // States
  const [checkboxes, setCheckboxes] = useState<{
    company_policy: boolean;
    algo_interview_policy: boolean;
  }>({
    company_policy: false,
    algo_interview_policy: false,
  });

  // Computed
  const allCheckboxesChecked: boolean = Object.values(checkboxes).every((value: boolean) => value);
  const ALGO_PRIVACY_POLICY_URL = import.meta.env.VITE_ALGO_PRIVACY_POLICY_URL;

  if (!currentStep || currentStep.type !== 'introduction') {
    return null;
  }

  return (
    <div
      className={clsx(
        'mx-auto flex h-full w-full max-w-[1600px] flex-col p-4',
        'lg:max-h-[980px] lg:flex-row-reverse lg:items-stretch lg:gap-32 lg:p-16'
      )}
    >
      {/* Cover section */}
      <div className={clsx('mb-4 flex w-full', 'lg:w-auto lg:flex-1')}>
        {/* Mobile cover */}
        {jobPostData?.['mobile_cover'] && (
          <div
            className={clsx(
              'hidden h-[240px] w-full rounded-2xl bg-cover bg-center bg-no-repeat',
              'xs:flex',
              'lg:hidden'
            )}
            style={{
              backgroundImage: `url(${jobPostData['mobile_cover']})`,
            }}
          />
        )}

        {/* Desktop cover */}
        {jobPostData?.['desktop_cover'] && (
          <div
            className={clsx(
              'hidden h-full w-full rounded-2xl bg-cover bg-center bg-no-repeat',
              'lg:flex'
            )}
            style={{
              backgroundImage: `url(${jobPostData['desktop_cover']})`,
            }}
          />
        )}
      </div>
      <div className={clsx('flex w-full', 'lg:w-auto lg:flex-1 lg:flex-col')}>
        <div className={clsx('mb-28', 'lg:mb-0 lg:max-w-[480px]')}>
          <JobHeader />
          {/* (Custom) Introduction text */}
          <div className={clsx('mt-4')}>
            <DescriptionText description={jobPostData?.introduction_content ?? ''} />
          </div>
          {/* (Custom) Welcome video */}
          {jobPostData?.welcome_video_url && (
            <div className={clsx('mt-4', 'lg:mt-10')}>
              <VideoPlayer video={jobPostData.welcome_video_url} />
            </div>
          )}
          {/* Privacy policies */}
          {!currentStep.data.policies_accepted && (
            <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
              <div className={clsx('mb-4 w-full text-[#b37800]', { hidden: allCheckboxesChecked })}>
                {t('intro.privacy.disclaimer')}
              </div>
              {/* CheckBoxes */}
              <div className="mb-20 flex w-full flex-col lg:mb-0">
                {Object.keys(checkboxes).map((checkbox: string) => (
                  <Checkbox
                    key={`introduction-checkbox-${checkbox}`}
                    name={checkbox}
                    label={
                      checkbox === 'company_policy'
                        ? t(`intro.privacy.${checkbox}`, {
                            companyName: companyData?.name,
                            companyPolicyUrl: companyData?.privacy_policy_url,
                          })
                        : checkbox === 'algo_interview_policy'
                          ? t(`intro.privacy.${checkbox}`, {
                              algoPolicyUrl: ALGO_PRIVACY_POLICY_URL,
                            })
                          : t(`intro.privacy.${checkbox}`)
                    }
                    onChange={(checked: boolean) =>
                      setCheckboxes((state) => ({
                        ...state,
                        [checkbox]: checked,
                      }))
                    }
                  />
                ))}
              </div>
            </div>
          )}
          {/* Footer */}
          <div
            className={clsx(
              'border-top fixed bottom-0 left-0 right-0 border-t-[1px] border-[#DDDDDD] bg-white p-4',
              'sm:static sm:mt-10 sm:border-0 sm:bg-transparent sm:p-0'
            )}
          >
            <Button
              loading={loading}
              disabled={!currentStep.data.policies_accepted && !allCheckboxesChecked}
              className={clsx(
                'default mx-auto !w-full gap-2 !rounded-lg !border-transparent',
                'sm:mx-0 sm:!w-auto'
              )}
              onClick={onSubmit}
            >
              {t('intro.button.start')}
              <AngleRightIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroBox;
