import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ThumbsUpCircleGreenIcon from '../../shared/components/Icons/ThumbsUpCircleGreenIcon';
import JobHeader from '../../shared/components/JobHeader/JobHeader';
import { SupportEmail } from '../../shared/components/SupportEmail/SupportEmail';
import { actions as examActions } from '../../store/exam/reducer';
import { getJobPostData } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import DescriptionText from '../Intro/components/DescriptionText';

const ExamDone = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Selectors
  const jobPostData = useAppSelector(getJobPostData);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (!timeout) {
      timeout = setTimeout(() => {
        dispatch(examActions.DISABLE_LOADING());
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch]);

  return (
    <div
      className={clsx(
        'mx-auto flex h-full w-full max-w-[1600px] flex-col p-4',
        'lg:max-h-[980px] lg:flex-row-reverse lg:items-stretch lg:gap-32 lg:p-16'
      )}
    >
      {/* Cover section */}
      <div className={clsx('mb-4 flex w-full', 'lg:w-auto lg:flex-1')}>
        {/* Mobile cover */}
        {jobPostData?.['mobile_cover'] && (
          <div
            className={clsx(
              'flex h-[240px] w-full rounded-2xl bg-cover bg-center bg-no-repeat',
              'lg:hidden'
            )}
            style={{
              backgroundImage: `url(${jobPostData['mobile_cover']})`,
            }}
          />
        )}

        {/* Desktop cover */}
        {jobPostData?.['desktop_cover'] && (
          <div
            className={clsx(
              'hidden h-full w-full rounded-2xl bg-cover bg-center bg-no-repeat',
              'lg:flex'
            )}
            style={{
              backgroundImage: `url(${jobPostData['desktop_cover']})`,
            }}
          />
        )}
      </div>

      {/* Copy section */}
      <div className={clsx('flex w-full flex-1 justify-center', 'sm:w-auto sm:flex-col')}>
        <div className="flex h-full flex-col">
          {/* Header */}
          <JobHeader />

          {/* Content */}
          <div
            className={clsx(
              'mt-5 flex w-full flex-1 flex-col items-center justify-center px-4',
              'lg:items-start lg:px-0'
            )}
          >
            <div
              className={clsx(
                'flex h-12 w-12 items-center justify-center rounded-full bg-[var(--accent-color)]',
                'sm:h-24 sm:w-24'
              )}
            >
              <ThumbsUpCircleGreenIcon
                className="sm:hidden"
                color="var(--text-color)"
              />
              <ThumbsUpCircleGreenIcon
                className="hidden sm:block"
                width={48}
                height={48}
                color="var(--text-color)"
              />
            </div>
            <h3
              className={clsx('my-4 text-3xl font-semibold leading-9', 'sm:my-10 sm:text-6xl')}
              dangerouslySetInnerHTML={{
                __html: jobPostData?.['completion_title'] ?? '',
              }}
            />
            <DescriptionText
              className={clsx('text-base font-normal leading-6 text-[#525252]', 'sm:text-2xl')}
              description={jobPostData?.['completion_content'] ?? ''}
            />
            {/* Footer Desktop */}
            <div className="mt-14 hidden flex-col items-start justify-center pt-4 sm:flex">
              <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
            </div>
          </div>

          {/* Footer */}
          {/* Footer Mobile */}
          <div className="flex w-full flex-col items-center justify-center p-4 sm:hidden">
            <div className="text-sm">
              {t('needHelp')} {t('contactUs')} <SupportEmail />
            </div>
            <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamDone;
